import React from 'react';
import { Box, BoxProps } from '../next-components/box';
type VideoElemProps = BoxProps & JSX.IntrinsicElements['video'];
type IframeElemProps = BoxProps & JSX.IntrinsicElements['iframe'];
export type Props = VideoElemProps & {
  useIframe?: boolean;
  iframeProps?: IframeElemProps;
};
export const Video = ({
  useIframe,
  iframeProps,
  src,
  ...rest
}: Props) => <>
    {useIframe ? <Box as="iframe" src={src} {...iframeProps} /> : <Box as="video" src={src} {...rest} />}
  </>;