/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Markdown, { Options } from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import omit from 'ramda/src/omit';
import { Link } from '../../../Link';
import { Typography } from '../../../Typography';
import { theme } from '../../../../theme';
import { LazyImage } from '../../../../wag-react/components/lazyload/LazyImage';
import { UL } from '../../../../wag-react/core-components/ul';
import { LI } from '../../../../wag-react/core-components/li';
import { Strong } from '../../../../wag-react/core-components/strong';
export const defaultComponents: Options['components'] = {
  h1: ({
    node,
    ...props
  }) => <Typography variant="h1" {...props}>{props.children} </Typography>,
  h2: ({
    node,
    ...props
  }) => <Typography variant="h2" {...props}>{props.children} </Typography>,
  h3: ({
    node,
    ...props
  }) => <Typography variant="h3" {...props}>{props.children} </Typography>,
  h4: ({
    node,
    ...props
  }) => <Typography variant="h4" {...props}>{props.children} </Typography>,
  h5: ({
    node,
    ...props
  }) => <Typography variant="h5" {...props}>{props.children} </Typography>,
  p: ({
    node,
    ...props
  }) => <Typography variant="body1" {...props}>{props.children} </Typography>,
  span: ({
    node,
    ...props
  }) => {
    const {
      style
    } = props;
    /**
     * Omit specific props to maintain consistency display
     *
     * TODO - should we do this on other typography elements too?
     * TODO - should we prevent CMS from using `span`? How?
     */
    const restProps = omit(['fontSize', 'fontFamily', 'color'], props);
    const restStyle = omit(['fontSize', 'fontFamily', 'color'], style);
    return <Typography variant="body2" {...restProps as typeof props} style={restStyle}>
        {props.children}
      </Typography>;
  },
  ul: ({
    node,
    ...props
  }) => <UL display="grid" gridGap="4px" fontSize={['mobile-body1', 'tablet-body1', 'desktop-body1']} {...props} />,
  li: ({
    node,
    ...props
  }) => <LI color="medGray" {...props} />,
  a: ({
    node,
    ...props
  }) => <Link
  // The `[key: string]: unknown;` isn't something this component like
  href={props.href} {...props} target="_blank" color="wagGreen" fontFamily="muliBold" style={{
    wordWrap: 'break-word'
  }}>
      {props.children}
    </Link>,
  b: ({
    node,
    ...props
  }) => <Strong {...props} color={theme.colors.medGray} />,
  img: ({
    node,
    ...props
  }) => {
    // The `[key: string]: unknown;` isn't something this component like
    const {
      src,
      width,
      height
    } = props;
    return <LazyImage src={src} {...props}
    // On mobile and tablet, use a 4:3 aspect ratio
    // on large screens, use the provided height/width
    width={['336px', '490px', width]} height={['252px', '368px', height]} />;
  }
};
export type ReactMarkdownProps = Options;
export const ReactMarkdown = ({
  children,
  components = defaultComponents,
  ...rest
}: ReactMarkdownProps) => <Markdown rehypePlugins={[rehypeRaw]} components={components} {...rest} data-sentry-element="Markdown" data-sentry-component="ReactMarkdown" data-sentry-source-file="index.tsx">
    {children}
  </Markdown>;