'use client';

import React, { useState, useEffect, useCallback } from 'react';
import { parseUrl, stringifyUrl } from 'query-string';
import { LazyImage, LazyImageProps } from './LazyImage';
import { YoutubePlayIcon } from '../../next-components/icons';
import { useGetResponsivePropValue } from '../../common/hooks/useGetResponsivePropValue';
import { Video, Props as VideoProps } from '../../core-components/video';
import { Box, BoxProps } from '../../next-components/box';

// https://github.com/paulirish/lite-youtube-embed/blob/master/src/lite-yt-embed.js#L87
export const DefaultConnectionsToWarm = ['https://www.youtube-nocookie.com', 'https://www.google.com', 'https://googleads.g.doubleclick.net', 'https://static.doubleclick.net'] as const;
export const DefaultBaseUrls = {
  Webp: 'https://i.ytimg.com/vi_webp',
  Basic: 'https://i.ytimg.com/vi',
  YoutubeEmbed: 'https://youtube.com/embed'
} as const;
const detectIfEmbedUrl = (videoUrl: string) => videoUrl.includes('embed');

/**
 * Extract the video id from the given url whether it is an embed url or a normal one
 *
 * Examples:
 * https://www.youtube.com/watch?v=fpBXrpa7y_4 -> fpBXrpa7y_4
 * https://youtube.com/embed/fpBXrpa7y_4 -> fpBXrpa7y_4
 */
const extractVideoIdFromUrl = (videoUrl: string) => {
  const isEmbedUrl = detectIfEmbedUrl(videoUrl);
  const {
    url: parsedUrl,
    query
  } = parseUrl(videoUrl);
  if (isEmbedUrl) {
    const videoId = parsedUrl.substring(parsedUrl.lastIndexOf('/') + 1);
    return videoId;
  }
  const {
    v: queryVideoId
  } = query;
  const videoId = Array.isArray(queryVideoId) ? [...queryVideoId].shift() : queryVideoId;
  return videoId;
};

/**
 * Creates an Youtube embed url in order to play it inside the iframe
 */
const createEmbedUrlFromVideoUrl = (videoUrl: string) => {
  const isEmbedUrl = detectIfEmbedUrl(videoUrl);
  const {
    url: parsedUrl,
    query: parsedQuery
  } = parseUrl(videoUrl);
  const query = {
    ...parsedQuery,
    // Autoplay should always be present to avoid double clicking the element
    autoplay: 1
  };

  // Use the `videoUrl` if it's not an embed url since the video id there is on the query string
  const videoId = isEmbedUrl ? extractVideoIdFromUrl(parsedUrl) : extractVideoIdFromUrl(videoUrl);
  const embedUrl = stringifyUrl({
    url: `${DefaultBaseUrls.YoutubeEmbed}/${videoId}`,
    query
  });
  return embedUrl;
};
const buildThumbnailUrl = (videoId: string, resolution: ThumbnailResolution) => {
  const webp = `${DefaultBaseUrls.Webp}/${videoId}/${resolution}.webp`;
  const basic = `${DefaultBaseUrls.Basic}/${videoId}/${resolution}.jpg`;
  return {
    url: webp,
    fallbackUrl: basic
  } as const;
};
export type ThumbnailResolution = 'hqdefault' | 'mqdefault' | 'sddefault' | 'maxresdefault';
export type LazyYoutubeProps = Omit<BoxProps, 'src'> & {
  connectionsToWarm?: readonly string[];
  thumbnailResolution?: ThumbnailResolution[];
  src: string;
  playIconProps?: BoxProps;
  thumbnailProps?: LazyImageProps;
  iframeProps?: VideoProps;
};
export const LazyYoutube = ({
  connectionsToWarm = DefaultConnectionsToWarm,
  thumbnailResolution = ['hqdefault', 'maxresdefault'],
  src,
  playIconProps,
  thumbnailProps,
  iframeProps,
  width = ['100%', '100%', '475px'],
  height = '267px',
  ...rest
}: LazyYoutubeProps) => {
  const [shouldWarmConnections, setShouldWarmConnections] = useState(false);
  const [isHoveringContainer, setIsHoveringContainer] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const [isIFrameShowing, setIsIframeShowing] = useState(false);
  const resolution = useGetResponsivePropValue(thumbnailResolution);
  const videoId = extractVideoIdFromUrl(src);
  const videoUrl = createEmbedUrlFromVideoUrl(src);
  const initializeThumbnail = useCallback(() => {
    const thumbnail = buildThumbnailUrl(videoId, resolution);

    // Load the image via Image element
    const img = new Image();
    img.src = thumbnail.url;
    img.onload = () => {
      setThumbnailUrl(thumbnail.url);
    };
    img.onerror = () => {
      // If we can't load the webp image (i.e not supported by the browser)
      // we use the fallback url
      setThumbnailUrl(thumbnail.fallbackUrl);
    };
  }, [resolution, videoId]);
  useEffect(() => {
    initializeThumbnail();
  }, [initializeThumbnail]);
  return <>
      {shouldWarmConnections && connectionsToWarm.map(connectionToWarm => <link key={connectionToWarm} rel="preconnect" href={connectionToWarm} />)}
      <Box width={width} height={height} position="relative" cursor="pointer" onClick={() => {
      setIsIframeShowing(true);
    }} onPointerOver={() => {
      setShouldWarmConnections(true);
      setIsHoveringContainer(true);
    }} onPointerLeave={() => {
      setIsHoveringContainer(false);
    }} {...rest} data-sentry-element="Box" data-sentry-source-file="LazyYoutube.tsx">
        <YoutubePlayIcon display={isIFrameShowing ? 'none' : 'block'} color={isHoveringContainer ? 'red' : 'gray'} position="absolute" cursor="pointer" width="68px" height="48px" zIndex={1} left="50%" top="50%" style={{
        transform: 'translate(-50%, -50%)'
      }} {...playIconProps} data-sentry-element="YoutubePlayIcon" data-sentry-source-file="LazyYoutube.tsx" />
        <LazyImage src={thumbnailUrl} width={width} height={height} display={isIFrameShowing ? 'none' : 'block'} {...thumbnailProps} data-sentry-element="LazyImage" data-sentry-source-file="LazyYoutube.tsx" />
        {isIFrameShowing && <Video useIframe allowFullScreen iframeProps={{
        allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
        width: width as unknown as string,
        height: height as unknown as string,
        ...iframeProps
      }} src={videoUrl} />}
      </Box>
    </>;
};